'use client'
import React from 'react'

interface IProps {
  title?: string
  description?: string
}

const Hero = (props: IProps) => {
  const { title, description } = props
  return (
    <section className="top-0 h-screen w-full bg-hero bg-cover bg-no-repeat">
      <div className="mx-auto w-full px-12 pt-24 text-left">
        <h1 className="font-display mb-10 text-4xl text-white lg:text-5xl xl:text-6xl">
          {title || 'A La Mexicana'}
        </h1>
        <span className="text-white ">
          {description ||
            'Entrevistas con personajes de la industria del software.'}
        </span>
      </div>
    </section>
  )
}

export default Hero
